<template>
  <main class="password">
    <h1>비밀번호 변경</h1>
    <ValidationObserver v-slot="{ invalid, untouched }">
      <form @submit="onSubmit">
        <div>
          <p>현재 비밀번호</p>
          <ValidationProvider
            name="현재 비밀번호"
            rules="required"
            v-slot="{ errors }"
            ref="refPass"
          >
            <input
              type="password"
              placeholder="현재 비밀번호를 입력해주세요."
              v-model="pass"
              ref="refPassInput"
              :disabled="submitStatus === 'PENDING'"
              :class="{ active: errors[0] }"
            />
            <span>{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div>
          <p>새 비밀번호</p>
          <ValidationProvider
            name="새 비밀번호"
            rules="required|password"
            ref="refNewPass"
            vid="newPass"
            v-slot="{ errors }"
          >
            <input
              type="password"
              placeholder="변경할 비밀번호를 입력해주세요."
              v-model="newPass"
              ref="refNewPassInput"
              :disabled="submitStatus === 'PENDING'"
              :class="{ active: errors[0] }"
            />
            <span>{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div>
          <p>비밀번호 확인</p>
          <ValidationProvider
            name="비밀번호 확인"
            rules="confirmed:newPass|required"
            ref="refNewRePass"
            v-slot="{ errors }"
          >
            <input
              type="password"
              placeholder="비밀번호를 입력해주세요."
              v-model="newRePass"
              ref="refNewRePass"
              :disabled="submitStatus === 'PENDING'"
              :class="{ active: errors[0] }"
            />
            <span>{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="btns">
          <base-button type="secondary" @click="$router.go(-1)"
            >이전</base-button
          >
          <base-button
            type="primary"
            nativeType="submit"
            :disabled="invalid || submitStatus === 'PENDING' || untouched"
            >{{
              submitStatus === "PENDING" ? "변경중.." : "변경하기"
            }}</base-button
          >
        </div>
      </form>
    </ValidationObserver>
  </main>
</template>

<script>
import BaseButton from "@/components/core/BaseButton.vue";
import client from "api-client";
import Dialog from "@/components/Dialog.vue";
import Crypto from "@/common/crypto";
export default {
  name: "Password",
  components: {
    BaseButton,
  },
  data() {
    return {
      pass: "",
      newPass: "",
      newRePass: "",
      submitStatus: null,
    };
  },
  methods: {
    async onSubmit(evt) {
      evt.preventDefault();
      this.submitStatus = "PENDING";
      let params = {};
      params.pass = Crypto.cryptoAesEncrypt(this.pass);
      params.newPass = Crypto.cryptoAesEncrypt(this.newPass);
      params.newRePass = Crypto.cryptoAesEncrypt(this.newRePass);

      await client.userModify(params).then(
        () => {
          this.submitStatus = "OK";
          this.$modal.show(
            Dialog,
            {
              title: "알림",
              content: "비밀번호 변경이 완료되었습니다.",
            },
            {
              adaptive: true,
              width: "90%",
              maxWidth: 620,
              height: "auto",
            },
            {
              "before-open": () => {},
              "before-close": () => {},
              closed: () => {
                if (!this.waitRequest) {
                  this.$store
                    .dispatch("logout")
                    .then(() => {
                      this.$router.push({
                        name: "Login",
                      });
                    })
                    .catch((message) => {
                      this.waitRequest = false;
                      this.$modal.show(
                        Dialog,
                        {
                          title: "알림",
                          content: message,
                        },
                        {
                          adaptive: true,
                          width: "90%",
                          maxWidth: 600,
                          height: "auto",
                        },
                        {
                          "before-open": () => {},
                          "before-close": () => {},
                        }
                      );
                    });
                }
              },
            }
          );
        },
        (error) => {
          this.submitStatus = "ERROR";
          const { data } = error.response;
          this.$modal.show(
            Dialog,
            {
              title: "알림",
              content: data.message,
            },
            {
              adaptive: true,
              width: "90%",
              maxWidth: 600,
              height: "auto",
            },
            {
              "before-open": () => {},
              "before-close": () => {},
            }
          );
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
